import { Box, Button, Card, CardContent, Chip, Divider, Stack, Switch, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import FacebookLogo from '@app/assets/png/logoFacebook.png';
import GoogleMeetLogo from '@app/assets/png/GoogleMeet.png';
import TencentLogo from '@app/assets/png/Tencent_Cloud.png';
import YoutubeLogo from '@app/assets/png/Youtube_Logo.png';
import ZoomLogo from '@app/assets/png/zoomLogo.png';
import ZoomLoginButton from '../components/ZoomLoginButton';
import { getAuthToken } from '../../Broadcast/services/facebook';
import {
  cancelGoogleMeetIntegration,
  cancelYoutubeIntegration,
  getGoogleMeetAuthToken,
  getGoogleMeetStatus,
  getMultiStatusToken,
  getStatusToken,
  getTencentStatus,
  getYoutubeAuthToken,
  getYoutubeStatus,
  googleMeetActivate,
  tencentActivate,
  youtubeActivate,
  zoomMultiActivate
} from '../services';
import addNotification from '../../../utils/addNotification';
import { useQuery } from 'react-query';
import TencentButton from '../components/TencentButton';
import ZoomLogoutButton from '../components/ZoomLogoutButton';
import TencentLogoutButton from '../components/TencentLogoutButton';
import CancelIntegrationButton from '../components/CancelIntegrationButton';
import DefaultLoginButton from '../components/DefaultLoginButton';
import DefaultLogoutButton from '../components/DefaultLogoutButton';

const Integrations = () => {
  const [zoomMultiIsActive, setZoomMultiIsActive] = useState(false);
  const [tencentImportIsActive, setTencentImportIsActive] = useState(false);
  const [tencentIntegrationIsActive, setTencentIntegrationIsActive] = useState(false);
  const [youtubeIntegrationIsActive, setYoutubeIntegrationIsActive] = useState(false);
  const [youtubeIsDeauthorized, setYoutubeIsDeauthorized] = useState(false);
  const [youtubeImportIsActive, setYoutubeIsActive] = useState(false);
  const [googleMeetImportIsActive, setGoogleMeetIsActive] = useState(false);
  const [googleMeetIntegrationIsActive, setGoogleMeetIntegrationIsActive] = useState(false);
  const [googleMeetIsDeauthorized, setGoogleIsDeauthorized] = useState(false);
  const [zoomMultiIsDeauthorized, setZoomMultiIsDeauthorized] = useState(true);
  const zoomButtonRef = useRef(null);
  const zoomMultiButtonRef = useRef(null);
  const zoomMultiLogoutButtonRef = useRef(null);
  const googleMeetLoginButtonRef = useRef(null);
  const googleMeetLogoutButtonRef = useRef(null);
  const youtubeLoginButtonRef = useRef(null);
  const youtubeLogoutButtonRef = useRef(null);
  const zoomLogoutButtonRef = useRef(null);
  const zoomQuery = useQuery('getStatusToken', getStatusToken, {
    retry: false,
    onSuccess: data => {
      if (data.find(account => account.deauthorized)) {
        zoomButtonRef.current.updateState(true);
      } else {
        zoomLogoutButtonRef.current.updateState(false);
      }
    },
    onError: error => {
      const notFound = error.message.includes('404');
      if (notFound) zoomButtonRef.current.updateState(true);
    }
  });
  const zoomMultiQuery = useQuery('getMultiStatusToken', getMultiStatusToken, {
    retry: false,
    onSuccess: data => {
      setZoomMultiIsActive(data.activeImport);
      if (data.deauthorized) {
        zoomMultiButtonRef.current.updateState(true);
      } else {
        zoomMultiLogoutButtonRef.current.updateState(false);
      }
      setZoomMultiIsDeauthorized(data.deauthorized);
    },
    onError: error => {
      const notFound = error.message.includes('404');
      if (notFound) setZoomMultiIsDeauthorized(true);
    }
  });
  const googleMeetQuery = useQuery('getGoogleMeetStatus', getGoogleMeetStatus, {
    retry: false,
    onSuccess: data => {
      setGoogleMeetIsActive(data.activeImport);
      if (data.deauthorized && !!googleMeetLoginButtonRef.current) googleMeetLoginButtonRef.current.updateState(true);
      setGoogleIsDeauthorized(data.deauthorized);
      setGoogleMeetIntegrationIsActive(!data.deauthorized);
    },
    onError: error => {
      const notFound = error.message.includes('404');
      if (notFound) setGoogleIsDeauthorized(true);
    }
  });
  const youtubeQuery = useQuery('getYoutubeStatus', getYoutubeStatus, {
    retry: false,
    onSuccess: data => {
      setYoutubeIsActive(data.enabled);
      if (data.deauthorized && !!googleMeetLoginButtonRef.current) googleMeetLoginButtonRef.current.updateState(true);
      setYoutubeIsDeauthorized(data.deauthorized);
      setYoutubeIntegrationIsActive(!data.deauthorized);
    },
    onError: error => {
      const notFound = error.message.includes('404');
      if (notFound) setGoogleIsDeauthorized(true);
    }
  });
  const { isLoading } = useQuery('getTencentStatus', getTencentStatus, {
    retry: false,
    onSuccess: data => {
      setTencentImportIsActive(data.activeImport);
      setTencentIntegrationIsActive(data.activeIntegration);
    }
  });
  const [isLoggedWithFacebook, setIsLoggedWithFacebook] = useState(false);
  useEffect(() => {
    if (document.cookie.includes('facebook_token')) {
      setIsLoggedWithFacebook(true);
    }
  }, []);
  const chipText = checked => (checked ? 'HABILITADO' : 'DESABILITADO');
  const chipColor = checked => (checked ? 'secondary' : 'error');
  const onSwitch = async (evt, setState, req, payload) => {
    try {
      setState(evt.target.checked);
      await req(payload);
    } catch {
      addNotification({ message: 'Falha ao tentar ativar importação.', type: 'danger' });
    }
  };
  const cards = [
    {
      title: 'Google Meet',
      image: GoogleMeetLogo,
      description: `Integre sua conta Google ao ${process.env.REACT_APP_SPALLA_NAME} Streaming para sincronizar automaticamente as gravações com a Biblioteca de Conteúdos.`,
      checked: googleMeetImportIsActive || false,
      onSwitch: evt => onSwitch(evt, setGoogleMeetIsActive, googleMeetActivate, { activeImport: evt.target.checked }),
      switchDisable: googleMeetIsDeauthorized,
      onChangeButton: (
        <DefaultLoginButton
          buttonText="Integração"
          disabled={googleMeetIntegrationIsActive}
          ref={googleMeetLoginButtonRef}
          getToken={getGoogleMeetAuthToken}
          isDeauthorized={googleMeetIsDeauthorized}
          query={googleMeetQuery}
          modalTitle="Integração Google Meet"
          modalDescription={`Integre as gravações feitas em sua conta Google Meet na biblioteca de conteúdos ${process.env.REACT_APP_SPALLA_NAME}.`}
        />
      ),
      onDeauthorizeButton: (
        <DefaultLogoutButton
          isLogoutButton
          buttonText="Desfazer Integração"
          ref={googleMeetLogoutButtonRef}
          isDeauthorized={googleMeetIsDeauthorized}
          disabled={!googleMeetIntegrationIsActive}
          query={googleMeetQuery}
          modalTitle="Desfazer integração Google Meet"
          modalDescription={`Ao desfazer a integração com o Google Meet, os conteúdos transferidos anteriormente para a biblioteca do ${process.env.REACT_APP_SPALLA_NAME} não serão perdidos.`}
          setGoogleMeetIntegrationIsActive={setGoogleMeetIntegrationIsActive}
          setGoogleMeetImportIsActive={setGoogleMeetIsActive}
          cancelIntegration={cancelGoogleMeetIntegration}
          cancelIntegrationText="Google Meet"
        />
      )
    },
    {
      title: 'Tencent Cloud',
      image: TencentLogo,
      description: `Aprimore sua experiência com o ${process.env.REACT_APP_SPALLA_NAME} ao incorporar o Tencent Cloud Com essa integração, todas as gravações produzidas no Tecent RTC serão automaticamente sincronizadas com a Biblioteca de Conteúdos ${process.env.REACT_APP_SPALLA_NAME}. Lembre-se de que você pode interromper essa integração a qualquer momento.`,
      checked: tencentImportIsActive || false,
      onSwitch: evt => onSwitch(evt, setTencentImportIsActive, tencentActivate, { activeImport: evt.target.checked }),
      switchDisable: !tencentIntegrationIsActive,
      onChangeButton: (
        <TencentButton
          disabled={tencentIntegrationIsActive}
          setTencentImportIsActive={setTencentImportIsActive}
          setTencentIntegrationIsActive={setTencentIntegrationIsActive}
          isLoading={isLoading}
        />
      ),
      onDeauthorizeButton: (
        <TencentLogoutButton
          disabled={!tencentIntegrationIsActive}
          setTencentIntegrationIsActive={setTencentIntegrationIsActive}
          setTencentImportIsActive={setTencentImportIsActive}
          isLoading={isLoading}
        />
      )
    },
    {
      title: 'Facebook Live',
      image: FacebookLogo,
      description: `Aprimore sua experiência ao integrar o ${process.env.REACT_APP_SPALLA_NAME} stream com o Facebook Live. Com essa integração é possível realizar transmissões simultâneas com o Facebook de maneira rápida e prática. Lembre-se de que você pode interromper essa integração a qualquer momento.`,
      onChangeButton: (
        <Button
          color="primary"
          disabled={isLoggedWithFacebook}
          size="large"
          sx={{ width: '100%', '&:disabled': { color: 'common.white', bgcolor: 'muted' } }}
          onClick={() => getAuthToken()}
        >
          Integração
        </Button>
      ),
      onDeauthorizeButton: <CancelIntegrationButton disabled={!isLoggedWithFacebook} onClick={() => getAuthToken()} />
    },
    {
      title: 'Zoom Meetings(Multi-contas)',
      image: ZoomLogo,
      description: `Conecte sua conta administradora Zoom com subcontas ao ${process.env.REACT_APP_SPALLA_NAME} para sincronizar automaticamente as gravações com a Biblioteca de Conteúdos ${process.env.REACT_APP_SPALLA_NAME}.`,
      checked: zoomMultiIsActive,
      switchDisable: zoomMultiIsDeauthorized,
      onSwitch: evt => onSwitch(evt, setZoomMultiIsActive, zoomMultiActivate, { activeImport: evt.target.checked }),
      onChangeButton: (
        <ZoomLoginButton
          isMultiAccounts
          key={zoomMultiQuery.isLoading}
          buttonText="Integração"
          ref={zoomMultiButtonRef}
          isDeauthorized={zoomMultiIsDeauthorized}
          zoomQuery={zoomMultiQuery}
        />
      ),
      onDeauthorizeButton: (
        <ZoomLogoutButton
          isLogoutButton
          buttonText="Desfazer Integração"
          ref={zoomMultiLogoutButtonRef}
          isDeauthorized={zoomMultiIsDeauthorized}
          zoomQuery={zoomMultiQuery}
        />
      )
    },
    {
      title: 'Zoom Meetings',
      image: ZoomLogo,
      description: `Conecte diversas contas Zoom ao ${process.env.REACT_APP_SPALLA_NAME} para sincronizar automaticamente as gravações com a Biblioteca de Conteúdos ${process.env.REACT_APP_SPALLA_NAME}.`,
      onChangeButton: (
        <ZoomLoginButton key={zoomQuery.isLoading} buttonText="Integração" ref={zoomButtonRef} isDeauthorized={false} zoomQuery={zoomQuery} />
      ),
      onDeauthorizeButton: (
        <ZoomLogoutButton isLogoutButton buttonText="Desfazer Integração" ref={zoomLogoutButtonRef} isDeauthorized={false} zoomQuery={zoomQuery} />
      )
    },
    {
      title: 'Youtube',
      image: YoutubeLogo,
      description: `Integre sua conta do YouTube ao ${process.env.REACT_APP_SPALLA_STUDIO_NAME} e realize transmissões automaticamente em ambas as plataformas.`,
      checked: youtubeImportIsActive || false,
      onSwitch: evt => onSwitch(evt, setYoutubeIsActive, youtubeActivate, { enabled: evt.target.checked }),
      switchDisable: youtubeIsDeauthorized,
      onChangeButton: (
        <DefaultLoginButton
          buttonText="Integração"
          disabled={youtubeIntegrationIsActive}
          ref={youtubeLoginButtonRef}
          isDeauthorized={youtubeIsDeauthorized}
          query={youtubeQuery}
          getToken={getYoutubeAuthToken}
          modalTitle="Integração Youtube"
          modalDescription={`Integre as transmissões feitas em sua conta do Youtube na biblioteca de conteúdos ${process.env.REACT_APP_SPALLA_NAME}.`}
        />
      ),
      onDeauthorizeButton: (
        <DefaultLogoutButton
          isLogoutButton
          buttonText="Desfazer Integração"
          ref={youtubeLogoutButtonRef}
          isDeauthorized={youtubeIsDeauthorized}
          disabled={!youtubeIntegrationIsActive}
          query={youtubeQuery}
          modalTitle="Desfazer integração com o Youtube"
          modalDescription={`Ao desfazer a integração com o Youtube, os conteúdos transferidos anteriormente para a biblioteca do ${process.env.REACT_APP_SPALLA_NAME} não serão perdidos.`}
          setGoogleMeetIntegrationIsActive={setYoutubeIntegrationIsActive}
          setGoogleMeetImportIsActive={setYoutubeIsActive}
          cancelIntegration={cancelYoutubeIntegration}
          cancelIntegrationText="Youtube"
        />
      )
    }
  ];
  useEffect(() => {
    document.title = `Integrações | ${process.env.REACT_APP_SPALLA_NAME}`;
  }, []);
  return (
    <>
      <Card>
        <CardContent>
          <Typography sx={{ color: 'neutral.body', fontSize: 16, fontWeight: 600 }}>Integrações</Typography>
        </CardContent>
      </Card>
      <Stack
        flexWrap="wrap"
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'space-between', lg: 'flex-start' }}
        gap={3}
        sx={{ mt: 3 }}
      >
        {cards.map(card => (
          <Card key={card.title} sx={{ width: { xs: '100%', md: '49%', lg: '32%' } }}>
            <CardContent>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                sx={{ width: '100%' }}
              >
                <Stack alignItems="center" direction="row" gap={2}>
                  {card.onSwitch && <Switch disabled={card.switchDisable} checked={card.checked} onChange={card.onSwitch} />}
                  <Typography sx={{ color: 'neutral.body', fontSize: 16, fontWeight: 600 }}>{card.title}</Typography>
                </Stack>
                {card.onSwitch && <Chip label={chipText(card.checked)} color={chipColor(card.checked)} />}
              </Stack>
              <Box
                sx={{
                  backgroundImage: `url(${card.image})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: 189,
                  width: '100%'
                }}
              />
              <Divider sx={{ bgcolor: 'grey.200', mb: 2 }} />
              <Typography sx={{ color: 'neutral.body', minHeight: { xs: 80, md: 120 } }}>{card.description}</Typography>
              <Divider sx={{ bgcolor: 'grey.200', my: 3 }} />
              <Stack gap={3}>
                {card.onChangeButton}
                {card.onDeauthorizeButton}
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </>
  );
};

export default Integrations;
