import { Routes } from '@app/Routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LayoutSplashScreen, MaterialThemeProvider } from '@metronic/layout';
import { PermissionsProvider } from '@tshio/react-router-permissions';
import React from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { PersistGate } from 'redux-persist/integration/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as yup from 'yup';
import { ConfirmationDialogProvider } from './components/ConfirmDialog';
import { I18nProvider } from './i18n/I18Context';
import ClassroomRoutes from './modules/Classroom/Routes';
import authorizationStrategy from './strategies/authorization';

export const setYupLocale = intl => {
  yup.setLocale({
    date: {
      min: ({ min }) => intl.formatMessage({ id: 'VALIDATION_ERRORS.DATE.MIN' }, { min }),
      max: ({ max }) => intl.formatMessage({ id: 'VALIDATION_ERRORS.DATE.MAX' }, { max })
    },
    mixed: {
      notType: () => intl.formatMessage({ id: 'VALIDATION_ERRORS.INVALID_FORMAT' }),
      required: () => intl.formatMessage({ id: 'VALIDATION_ERRORS.REQUIRED' })
    },
    number: {
      integer: () => intl.formatMessage({ id: 'VALIDATION_ERRORS.NUMBER.INTEGER' }),
      max: ({ max }) => intl.formatMessage({ id: 'VALIDATION_ERRORS.NUMBER.MAX' }, { max }),
      min: ({ min }) => intl.formatMessage({ id: 'VALIDATION_ERRORS.NUMBER.MIN' }, { min }),
      positive: () => intl.formatMessage({ id: 'VALIDATION_ERRORS.NUMBER.POSITIVE' })
    },
    string: {
      email: () => intl.formatMessage({ id: 'VALIDATION_ERRORS.EMAIL' }),
      min: ({ min }) => intl.formatMessage({ id: 'VALIDATION_ERRORS.MINLENGTH' }, { min }),
      max: ({ max }) => intl.formatMessage({ id: 'VALIDATION_ERRORS.MAXLENGTH' }, { max }),
      url: () => intl.formatMessage({ id: 'VALIDATION_ERRORS.URL' })
    }
  });
};

export const Permissions = ({ children }) => {
  const permissions = useSelector(state => state.auth.permissions || []);

  return (
    <PermissionsProvider permissions={permissions} authorizationStrategy={authorizationStrategy}>
      {children}
    </PermissionsProvider>
  );
};

export default function App({ store, persistor, basename }) {
  const { location } = window;
  const isClassroomRoutes = location.pathname.includes('classroom');
  return (
    /* Provide Redux store */
    <Provider store={store}>
      <MaterialThemeProvider>
        <ConfirmationDialogProvider>
          <QueryClientProvider
            client={
              new QueryClient({
                defaultOptions: { queries: { cacheTime: 0, refetchOnWindowFocus: false } }
              })
            }
          >
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
              <Permissions>
                {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
                <React.Suspense fallback={<LayoutSplashScreen />}>
                  {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                  <BrowserRouter basename={basename}>
                    <LastLocationProvider>
                      {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {/* Provide `react-intl` context synchronized with Redux state.  */}
                        <I18nProvider>
                          {/* Render routes with provided `Layout`. */}
                          <ReactNotification />
                          {isClassroomRoutes ? <ClassroomRoutes /> : <Routes />}
                        </I18nProvider>
                      </LocalizationProvider>
                    </LastLocationProvider>
                  </BrowserRouter>
                </React.Suspense>
              </Permissions>
            </PersistGate>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ConfirmationDialogProvider>
      </MaterialThemeProvider>
    </Provider>
  );
}
