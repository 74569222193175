/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { ErrorPage } from '@app/pages/ErrorPage';
import { Layout } from '@metronic/layout';
import jwt_decode from 'jwt-decode';
import React, { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, Route, useHistory } from 'react-router-dom';
import { setYupLocale } from './App';
import BasePage from './BasePage';
import ObserveWarningSignal from './components/ObserveWarningSignal';
import { Logout } from './modules/Auth';
import AuthModule from './modules/Auth/refactor/AuthModule';
import { actions } from './modules/Auth/_redux/authRedux';
import TeamSelector from './components/TeamSelector';
import BlockedPage from './pages/BlockedPage';

function ValidateUserTokenExp() {
  let isExpired = false;
  let authToken = false;

  const token = localStorage.getItem('persist:v706-demo1-auth');

  if (token) {
    authToken = JSON.parse(token).authToken;
  } else {
    isExpired = true;
    return isExpired;
  }

  if (authToken) {
    var decodedToken = jwt_decode(authToken);
    var dateNow = new Date();

    if (decodedToken.exp * 1000 < dateNow.getTime()) isExpired = true;
    if (isExpired) {
      localStorage.removeItem('persist:v706-demo1-auth');
    }
  }
  return isExpired;
}

export function Routes() {
  var isExpired = ValidateUserTokenExp();
  const intl = useIntl();

  const history = useHistory();
  const lastVisitedPage = useRef('/streaming');
  const shouldRedirectToAnalytics = useRef(false);
  const { location } = history;

  setYupLocale(intl);

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user !== undefined && auth.permissions !== undefined
    }),
    shallowEqual
  );
  const { auth } = useSelector(state => ({ auth: state.auth }), shallowEqual);
  const { user, contasCargos, permissions } = auth;
  const canManageTeamsUsers = permissions?.includes('manageTeamUsers');
  const teams = contasCargos && Object.values(contasCargos);
  const teamsCount = teams?.length;
  const dispatch = useDispatch();

  useEffect(() => {
    if (canManageTeamsUsers && !user.canManageTeamsUsers) {
      dispatch(
        actions.updateUserData({
          user: { ...user, canManageTeamsUsers: true }
        })
      );
    }
  }, [canManageTeamsUsers, user, dispatch]);

  const isAlfacon = user?.contaId === '857de2ae-ae73-4cb7-b950-996dca534321';
  useEffect(() => {
    if (!isAuthorized) {
      const authPath = location.pathname.includes('auth') || location.pathname.includes('logout');
      const hasAnalyticsRedirection = location.search.includes('redirectTo');
      if (authPath && hasAnalyticsRedirection) {
        shouldRedirectToAnalytics.current = true;
        lastVisitedPage.current = location.search.replace('?redirectTo=/', '');
        return;
      }
      if (!authPath) {
        lastVisitedPage.current = `${location.pathname}${location.search}`;
      }
    }
  }, [isAuthorized, location]);
  const resetLastVisitedPage = useCallback(() => {
    lastVisitedPage.current = '/streaming';
  }, []);
  return (
    <Switch>
      {!isAuthorized || isExpired ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthModule />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorPage} />
      <Route path="/logout" component={Logout} />
      <Route path="/blocked" component={BlockedPage} />

      {!isAuthorized || isExpired ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : isAlfacon ? (
        <>
          <Redirect to="blocked" />
        </>
      ) : (
        <>
          {/* {false ? ( */}
          {teamsCount > 1 && !user?.selectedTeamId ? (
            <TeamSelector teams={teams} />
          ) : (
            <Layout>
              <ObserveWarningSignal />
              <BasePage
                shouldRedirectToAnalytics={shouldRedirectToAnalytics.current}
                lastVisitedPage={lastVisitedPage.current}
                onResetLastVisitedPage={resetLastVisitedPage}
              />
            </Layout>
          )}
        </>
      )}
    </Switch>
  );
}
