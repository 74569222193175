import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Context } from '../Context';
import { getRoomInfo } from '../services';
import Chat from './components/Chat';
import Footer from './components/Footer';
import Header from './components/Header';
import Player from './components/Player';
import './styles.css';

const Classroom = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const playerContainer = useRef();
  const [height, setHeight] = useState(0);
  const { arenaUser, liveId } = useContext(Context);
  const { data, isLoading } = useQuery(['classroom'], () => getRoomInfo(liveId));
  const history = useHistory();
  useEffect(() => {
    if (!arenaUser) {
      history.push(`/classroom/register?liveId=${liveId}`);
    }
  }, [arenaUser, history, liveId]);
  const updatePlayerHeight = useCallback(() => {
    if (isMobile) {
      setHeight(window.innerWidth * (9 / 16));
    } else {
      setHeight(playerContainer.current?.getBoundingClientRect().width * (9 / 16));
    }
  }, [isMobile]);
  useEffect(() => {
    window.addEventListener('resize', updatePlayerHeight);
    updatePlayerHeight();
    return () => {
      window.removeEventListener('resize', updatePlayerHeight);
    };
  }, [updatePlayerHeight]);
  if (!arenaUser) {
    return <></>;
  }
  return (
    <Box
      sx={{
        backgroundImage: { lg: data?.background ? `url(${data.background})` : 'url(/media/classroom/default-background.jpg)' },
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        height: 'auto',
        maxWidth: '100%',
        position: 'relative',
        width: '100vw'
      }}
    >
      <Box sx={{ backgroundColor: '#000', height: '100%', left: 0, opacity: '50%', position: 'absolute', top: 0, width: '100%' }} />
      <Box sx={{ position: 'relative', width: '100%', minHeight: '100vh', maxWidth: '100vw' }}>
        <Header isLoading={isLoading && !data} infos={data} />
        <Box sx={{ px: { xs: 0, lg: 5 }, width: '100%' }}>
          <Stack direction={{ xs: 'column', lg: 'row' }} gap={{ xs: 0, lg: 3 }}>
            <Player height={height} playerContainer={playerContainer} />
            <Chat height={height} liveId={liveId} />
          </Stack>
          <Footer isLoading={isLoading && !data} infos={data} />
        </Box>
      </Box>
    </Box>
  );
};

export default Classroom;
