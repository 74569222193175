import { LogoutRounded as LogoutIcon, WarningRounded as WarningIcon } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { toAbsoluteUrl } from '../../_metronic/_helpers';

const BlockedPage = () => (
  <Stack sx={{ bgcolor: 'white', width: '100%' }}>
    <Stack alignItems="center" justifyContent="center" sx={{ height: 65 }}>
      <Box
        sx={{
          backgroundImage: `url(${toAbsoluteUrl(process.env.REACT_APP_SPALLA_LOGO)})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          height: 33,
          mt: 3,
          width: 112
        }}
      />
    </Stack>
    <Divider sx={{ bgcolor: 'grey.200', mt: 3 }} />
    <Stack alignItems="center" justifyContent="center" sx={{ mt: 10, p: 2, width: '100%' }}>
      <WarningIcon sx={{ color: 'muted', fontSize: 50, mb: 4 }} />
      <Typography sx={{ color: 'neutral.body', fontSize: 20, fontWeight: 600, mb: 2, textAlign: 'center' }}>
        Seu acesso está temporariamente limitado para subir e gerenciar novos vídeos!
      </Typography>
      <Typography sx={{ color: 'neutral.body', fontSize: 16, fontWeight: 400, maxWidth: 907, mb: 4, textAlign: 'center' }}>
        Mas não se preocupe, o Spalla continua entregando seus vídeos armazenados com a mesma qualidade de sempre! Para o acesso voltar ao normal,
        entre em contato com o financeiro
      </Typography>
      <Button href="/logout" startIcon={<LogoutIcon />}>
        Sair da plataforma
      </Button>
    </Stack>
  </Stack>
);

export default BlockedPage;
