import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { ArrowBackIosNewRounded as ArrowBackIcon, ArrowForwardIosRounded as ArrowForwardIcon } from '@mui/icons-material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import getSlidesResponsive from '../utils/getSlideResponsive';
import AliceCarousel from 'react-alice-carousel';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { actions } from '../modules/Auth/_redux/authRedux';

const TeamCard = ({ team }) => {
  const { auth } = useSelector(state => ({ auth: state.auth }), shallowEqual);
  const { user } = auth;
  const dispatch = useDispatch();
  const canManageTeamsUsers = user?.canManageTeamsUsers;
  const updatedData = {
    user: { ...user, cargo: team.cargo_nome, selectedTeamId: team.conta_id, cliente: team.cliente },
    permissions: canManageTeamsUsers ? [...team.permissoes, 'manageTeamUsers'] : team.permissoes
  };
  return (
    <Stack
      sx={{
        bgcolor: 'common.white',
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'rgb(209, 211, 224)',
        m: 'auto',
        p: 3,
        width: '90%'
      }}
    >
      <Typography sx={{ color: 'neutral.body', fontSize: 16, fontWeight: 600, mb: 1 }}>{team.conta_nome}</Typography>
      <Typography sx={{ color: 'muted', fontSize: 12, fontWeight: 600, mb: 1 }}>{team.cargo_nome}</Typography>
      <Stack
        sx={{
          backgroundImage: `url(${team.conta_thumbnail})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          borderRadius: 2,
          border: '1px solid',
          borderColor: 'rgb(209, 211, 224)',
          height: 148,
          mb: 3,
          width: '100%'
        }}
      />
      <Button onClick={() => dispatch(actions.updateUserData(updatedData))}>Acessar</Button>
    </Stack>
  );
};

const TeamSelector = ({ teams }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const carousel = useRef(null);
  const teamsItems = useMemo(() => {
    return teams.map(team => <TeamCard key={team.conta_id} team={team} />);
  }, [teams]);
  const totalItems = teams.length;
  const maxIndex = Math.ceil(totalItems / itemsPerPage) - 1;
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const responsive = getSlidesResponsive(2, 3, 4);
      const breakpoints = [
        { minWidth: 1080, items: responsive[1080].items },
        { minWidth: 1024, items: responsive[1024].items },
        { minWidth: 768, items: responsive[768].items }
      ];
      const { items } = breakpoints.find(breakpoint => width >= breakpoint.minWidth) || { items: 1 };
      setItemsPerPage(items);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const nextSlideHandler = evt => {
    if (activeIndex < maxIndex) {
      setActiveIndex(activeIndex + 1);
      return carousel?.current?.slideNext(evt);
    }
  };
  const prevSlideHandler = evt => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
      return carousel?.current?.slidePrev(evt);
    }
  };
  return (
    <Stack sx={{ backgroundImage: 'url(/media/png/teamsBackground.png)', backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '100vh' }}>
      <Stack alignItems="center" sx={{ mt: 9, width: '100%' }}>
        <Box
          sx={{
            backgroundImage: `url(${toAbsoluteUrl('/media/logos/logo-1.svg')})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            height: 64,
            mb: 4,
            width: 200
          }}
        />
        <Typography sx={{ color: 'neutral.body', fontSize: 24, fontWeight: 600, mb: 2 }}>Seja bem vindo a Spalla Stream</Typography>
        <Typography sx={{ color: 'neutral.50', fontSize: 16, fontWeight: 600, textAlign: 'center' }}>
          Para continuar, você precisa selecionar o time desejado.
          <br /> Você pode alterar o time durante a navegação.
        </Typography>
      </Stack>
      <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ mt: 9, px: { xs: 3, md: 9 } }}>
        <IconButton
          onClick={prevSlideHandler}
          sx={{ bgcolor: activeIndex > 0 ? 'primary.main' : 'neutral.25', borderRadius: '100%', height: 40, width: 40 }}
        >
          <ArrowBackIcon sx={{ color: 'common.white' }} />
        </IconButton>
        <Stack sx={{ width: '90%' }}>
          <AliceCarousel
            ref={carousel}
            activeIndex={activeIndex}
            animationDuration={800}
            animationType="fadeout"
            disableButtonsControls
            disableDotsControls
            items={teamsItems}
            responsive={getSlidesResponsive(2, 3, 4)}
          />
        </Stack>
        <IconButton
          onClick={nextSlideHandler}
          sx={{ bgcolor: activeIndex < maxIndex ? 'primary.main' : 'neutral.25', borderRadius: '100%', height: 40, width: 40 }}
        >
          <ArrowForwardIcon sx={{ color: 'common.white' }} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default TeamSelector;
