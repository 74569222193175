import jwt_decode from 'jwt-decode';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';

import { auth, getUserByToken } from './authCrud';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  UpdateUserData: '[Update User Data] Action'
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  permissions: undefined,
  contasCargos: undefined
};

export const reducer = persistReducer(
  {
    storage,
    key: 'v706-demo1-auth',
    whitelist: ['authToken', 'user', 'permissions', 'contasCargos']
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        return action.payload;
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;
        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user, permissions, contasCargos } = action.payload;
        return { ...state, user, permissions, contasCargos };
      }

      case actionTypes.UpdateUserData:
        return {
          ...state,
          user: {
            ...(state.user || {}),
            ...(action.payload.user || {})
          },
          ...action.payload
        };

      default:
        return state;
    }
  }
);

export const fetchLogin = (login, password, callback) => dispatch => {
  return auth(login, password)
    .then(res => {
      const token = res.data.Token;
      const decoded = jwt_decode(token);
      const payload = {
        user: login,
        level: decoded.role,
        authToken: res.data.Token
      };

      dispatch(actions.login(payload));
    })
    .catch(err => callback(err));
};

export const actions = {
  login: payload => ({
    type: actionTypes.Login,
    payload
  }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: (user, permissions, contasCargos) => ({
    type: actionTypes.UserLoaded,
    payload: { user, permissions, contasCargos }
  }),
  updateUserData: newData => ({
    type: actionTypes.UpdateUserData,
    payload: newData
  })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {
      data: { user, permissoes, contasCargos }
    } = yield getUserByToken();

    yield put(actions.fulfillUser(user, permissoes, contasCargos));
  });
}
