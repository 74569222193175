import ptBr from 'date-fns/locale/pt-BR';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Redirect, Route, Switch } from 'react-router-dom';
import { setUploadFile, uploadFileSpalla } from '@app/redux/upload/uploadFile.actions';
import { AuthorizedRoute } from '@tshio/react-router-permissions';
import { ContentRoute, LayoutSplashScreen } from '@metronic/layout';
import { ErrorPage } from './pages/ErrorPage';
import { ForbiddenPage } from './pages/ForbiddenPage';
import { PreviewPage } from './pages/PreviewPage';
import { useDispatch } from 'react-redux';
import Context from './Context';
import { createVOD } from './modules/VOD/services/vod';
import addNotification from './utils/addNotification';
import IntegrationsModule from './modules/Integrations/IntegrationsModule';
import RecordsModule from './modules/Records/RecordsModule';
const AnalyticsModule = lazy(() => import('./modules/Analytics/AnalyticsModule'));
const ApiKeysModule = lazy(() => import('./modules/ApiKeys/ApiKeysModule'));
const BillingModule = lazy(() => import('./modules/Billing/BillingModule'));
const BroadcastModule = lazy(() => import('./modules/Broadcast/BroadcastModule'));
const ClientsModule = lazy(() => import('./modules/Clients/ClientsModule'));
const HistoricModule = lazy(() => import('./modules/Historic/HistoricModule'));
const MembersModule = lazy(() => import('./modules/Members/MembersModule'));
const PermissionsModule = lazy(() => import('./modules/Permissions/PermissionsModule'));
const RolesModule = lazy(() => import('./modules/Roles/RolesModule'));
const StreamingModule = lazy(() => import('./modules/Streaming/StreamingModule'));
const SupportModule = lazy(() => import('./modules/Support/SupportModule'));
const TeamModule = lazy(() => import('./modules/Teams/TeamModule'));
const UsersModule = lazy(() => import('./modules/Users/UsersModule'));
const VODModule = lazy(() => import('./modules/VOD/VODModule'));
const OverlaysModule = lazy(() => import('./modules/Overlays/OverlaysModule'));
const AccountModule = lazy(() => import('./modules/Account/AccountModule'));

registerLocale('pt-br', ptBr);
setDefaultLocale('pt-br');

const BasePage = ({ shouldRedirectToAnalytics, lastVisitedPage, onResetLastVisitedPage }) => {
  const [fazendoUpload, setFazendoUpload] = useState(false);
  const [createdVideo, setCreatedVideo] = useState([]);
  const dispatch = useDispatch();
  const onBeforeUploadHandler = (acceptedFiles, directoryId, accountId) => {
    let videos = [];
    let hasError = false;
    let videosWithErrorCount = 0;
    setFazendoUpload(true);
    acceptedFiles.map(async (file, index) => {
      const videoFile = file;
      const isTheLast = acceptedFiles.length - 1 === index;
      try {
        const { data: video } = await createVOD({
          title: file.name,
          directoryId: directoryId || '',
          accountId: accountId || ''
        });
        if (video.videoId) {
          videos.push({ ...video, ...file });
          dispatch(setUploadFile([videoFile]));
          dispatch(uploadFileSpalla(video.videoId, videoFile));
        }
      } catch {
        videosWithErrorCount++;
        hasError = true;
      } finally {
        if (hasError && isTheLast) {
          if (videosWithErrorCount > 1) {
            addNotification({ message: 'Erro ao criar estes conteúdos, tente novamente.', type: 'danger' });
          } else {
            addNotification({ message: 'Erro ao criar este conteúdo, tente novamente.', type: 'danger' });
          }
          setFazendoUpload(false);
        }
      }
    });
    setCreatedVideo(videos);
  };
  const isStudioPath = lastVisitedPage.includes('webcam');
  useEffect(() => {
    if (shouldRedirectToAnalytics) {
      window.location.href = `${process.env.REACT_APP_ANALYTICS_URL}${lastVisitedPage}`;
    }
    if (isStudioPath) {
      const wordWebcamRemoved = lastVisitedPage.replace('webcam/', '');
      window.location.href = `${process.env.REACT_APP_SPALLA_STUDIO_URL}${wordWebcamRemoved}`;
    }
  }, [isStudioPath, lastVisitedPage, shouldRedirectToAnalytics]);
  const defaultPage = shouldRedirectToAnalytics || isStudioPath ? '/streaming' : lastVisitedPage;
  useEffect(() => {
    setTimeout(() => {
      onResetLastVisitedPage();
    }, 1000);
  }, [onResetLastVisitedPage]);
  return (
    <Context.Provider value={{ onBeforeUploadHandler, fazendoUpload, setFazendoUpload, createdVideo, setCreatedVideo }}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          <AuthorizedRoute path="/analytics" requires="showStreamingAnalytics">
            {({ isAuthorized }) => (isAuthorized ? <AnalyticsModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <AuthorizedRoute path="/api-keys" requires="showClients">
            {({ isAuthorized }) => (isAuthorized ? <ApiKeysModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <AuthorizedRoute path="/billing" requires="showFinancial">
            {({ isAuthorized }) => (isAuthorized ? <BillingModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <AuthorizedRoute path="/broadcast" requires={['broadcast']}>
            {({ isAuthorized }) => (isAuthorized ? <BroadcastModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <AuthorizedRoute path="/clients" requires="showClients">
            {({ isAuthorized }) => (isAuthorized ? <ClientsModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <AuthorizedRoute path="/historic" requires="showHistory">
            {({ isAuthorized }) => (isAuthorized ? <HistoricModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <AuthorizedRoute path="/permissions" requires="showPermissions">
            {({ isAuthorized }) => (isAuthorized ? <PermissionsModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <AuthorizedRoute path="/roles" requires="showRoles">
            {({ isAuthorized }) => (isAuthorized ? <RolesModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <AuthorizedRoute path="/streaming" requires="showStreaming">
            {({ isAuthorized }) => (isAuthorized ? <StreamingModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <AuthorizedRoute path="/atendimento/:liveId" requires="showCustomerSupport">
            {({ isAuthorized }) => (isAuthorized ? <SupportModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <AuthorizedRoute path="/users" requires={['showUsers', 'manageTeamUsers']}>
            {({ isAuthorized }) => (isAuthorized ? <UsersModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <AuthorizedRoute path="/vod" requires="showVOD">
            {({ isAuthorized }) => (isAuthorized ? <VODModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <AuthorizedRoute path="/members" requires={['showVOD', 'manageTeamUsers']}>
            {({ isAuthorized }) => (isAuthorized ? <MembersModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <AuthorizedRoute path="/teams" requires={['gerenciarTimes', 'manageTeamUsers']}>
            {({ isAuthorized }) => isAuthorized && <TeamModule />}
          </AuthorizedRoute>
          <AuthorizedRoute path="/account/settings" requires="showAccountSettings">
            {({ isAuthorized }) => (isAuthorized ? <AccountModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <AuthorizedRoute path="/integrations" requires={['spalla-dev', 'integrations']}>
            {({ isAuthorized }) => (isAuthorized ? <IntegrationsModule /> : <Redirect to="/403" />)}
          </AuthorizedRoute>
          <ContentRoute path="/preview/:signalId/:liveId" component={PreviewPage} />
          <ContentRoute path="/overlays" component={OverlaysModule} />
          <ContentRoute path="/records" component={RecordsModule} />
          <Route path="/not-found" component={ErrorPage} />
          <Route path="/403" component={ForbiddenPage} />
          <Redirect exact from="/" to={defaultPage} />
          <Redirect to="error" />
        </Switch>
      </Suspense>
    </Context.Provider>
  );
};
export default BasePage;
