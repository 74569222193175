import { KeyboardArrowDownRounded as KeyboardArrowDownIcon } from '@mui/icons-material';
import { Menu, Stack, Typography } from '@mui/material';
import React from 'react';

const CustomDropdown = ({ anchorEl, setAnchorEl, children, icon, title }) => {
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Stack alignItems="center" direction="row" sx={{ cursor: 'pointer' }} onClick={handleClick}>
        {icon}
        <Typography sx={{ color: 'neutral.body', fontWeight: 600, fontSize: { xs: 12, md: 14 }, mr: 2 }}>{title}</Typography>
        <KeyboardArrowDownIcon />
      </Stack>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          sx: { borderRadius: 3, maxHeight: 500, overflow: 'hidden' }
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export default CustomDropdown;
