export const normalizeString = str =>
  str
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();

export const compareTerms = (term, termToCompare) => {
  const normalizedTerm = normalizeString(term.toLowerCase());
  const normalizedTermToCompare = normalizeString(termToCompare.toLowerCase());
  const didMatch = normalizedTerm.includes(normalizedTermToCompare);
  return didMatch;
};

export const validateStringLength = (string, minimumSize = 3) => {
  if (typeof string !== 'string') {
    throw new TypeError('The first argument must be a string');
  }
  if (typeof minimumSize !== 'number' || minimumSize < 0) {
    throw new TypeError('The second argument must be a non-negative number');
  }
  return string.trim().length >= minimumSize;
};
