import { Box } from '@mui/material';
import React from 'react';

const Chat = ({ height, liveId }) => {
  return (
    <Box sx={{ height: { xs: 480, lg: height }, width: { xs: '100%', lg: '32%' } }}>
      <iframe
        title={liveId}
        src={`${process.env.REACT_APP_CHAT_URL}?liveId=${liveId}`}
        style={{ border: 'none', borderRadius: '16px', height: '100%', width: '100%' }}
      />
    </Box>
  );
};

export default Chat;
