import { Avatar, Button, Grid, IconButton, List, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { InfoOutlined as InfoOutlinedIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import React from 'react';
import { useIntl } from 'react-intl';
import LogModal from './LogModal';

const DesktopRow = ({ data, open, setOpen, showMore, setShowMore, showMoreButtonLabel, recordIsArray, eventType, createdAt, icon }) => {
  const intl = useIntl();
  return (
    <Grid container>
      <Grid item xs={1.5} sx={{ mt: 2 }}>
        <Typography sx={{ color: 'neutral.50', fontSize: 12, fontWeight: 400, ml: 4 }}>{`${createdAt.date} ${createdAt.time}`}</Typography>
      </Grid>
      <Grid item xs={0.5} sx={{ pt: 1, display: 'flex', justifyContent: 'center', width: 60 }}>
        {icon()}
      </Grid>
      <Grid item xs={5.5} sx={{ mt: 2 }}>
        <Stack>
          <Typography
            sx={{
              color: 'neutral.body',
              display: 'inline-block',
              fontSize: { xs: 12, lg: 14 },
              fontWeight: 400,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {eventType}:{' '}
            <Typography component="span" sx={{ color: 'neutral.body', display: 'inline-block', fontSize: { xs: 12, lg: 14 }, fontWeight: 600 }}>
              {data?.identifier}
            </Typography>
          </Typography>
          {recordIsArray && showMore && (
            <List sx={{ listStyleType: 'disc', pl: 4 }}>
              {data?.record
                .filter(record => record.Value !== null && (!Array.isArray(record.Value) || record.Value.length > 0) && record.Value !== '')
                .map(record => {
                  let jsonObject = {};
                  let stringValues = '';
                  if (Array.isArray(record.Value)) {
                    record.Value.forEach(rec => {
                      typeof rec === 'string' ? (stringValues = stringValues.concat(', ', rec)) : (jsonObject[rec.Key] = rec.Value);
                    });
                  } else {
                    jsonObject[record.Key] = record.Value;
                  }
                  const formattedJson = JSON.stringify(jsonObject, null, 2);
                  const jsonLabel =
                    stringValues.replace(',', ' ') ||
                    formattedJson
                      .toString()
                      .replaceAll(',', '<br/>')
                      .replaceAll('}', ' ')
                      .replaceAll('{', ' ')
                      .replaceAll('"', ' ');
                  const valueLabel = Array.isArray(record.Value) ? (
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: jsonLabel
                      }}
                      sx={{ color: 'neutral.body', display: 'inline-block', fontSize: 14, fontWeight: 600, ml: 4 }}
                    />
                  ) : (
                    <Typography sx={{ color: 'neutral.body', display: 'inline-block', fontSize: 14, fontWeight: 600 }}>
                      {String(record.Value)}
                    </Typography>
                  );
                  return (
                    <ListItem key={record.Key} sx={{ display: 'list-item', p: 0 }}>
                      <Typography sx={{ color: 'neutral.body', fontSize: 14, fontWeight: 400 }}>
                        {record.Key}: {valueLabel}
                      </Typography>
                    </ListItem>
                  );
                })}
            </List>
          )}
        </Stack>
      </Grid>
      <Grid item xs={1.5} sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip title={data?.user.email} placement="top">
          <Typography
            sx={{
              color: 'neutral.body',
              fontSize: { xs: 12, lg: 14 },
              fontWeight: 400,
              textAlign: { xs: 'center', md: 'auto' },
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {data?.user.email}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid container item xs={3} sx={{ mt: 2, display: 'flex', pr: { md: 1 } }}>
        <Avatar src={data?.user.avatar} sx={{ height: 24, mx: { xs: 2, md: 3 }, width: 24 }} />
        <LogModal data={data} open={open} setOpen={setOpen}>
          <Tooltip title={intl.formatMessage({ id: 'LOGS.LIST.TABLE_BODY.INFO_BUTTON.TOOLTIP' })}>
            <IconButton sx={{ borderRadius: 1, bgcolor: 'neutral.light', color: 'neutral.50', height: 24, width: 24 }} onClick={() => setOpen(true)}>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </LogModal>
        {recordIsArray && (
          <Button
            color="secondary"
            endIcon={<KeyboardArrowDownIcon sx={{ transform: showMore ? 'rotate(3.142rad)' : 'unset' }} />}
            sx={{ fontSize: { xs: 9, lg: 14 }, height: 24, ml: { xs: 2, md: 'auto' }, px: { xs: 1, md: 3 }, whiteSpace: 'nowrap' }}
            onClick={() => setShowMore(prevState => !prevState)}
          >
            {showMoreButtonLabel}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default DesktopRow;
