import { normalizeString } from '@app/utils/string';
import { Button, Divider, IconButton, OutlinedInput, Stack, Typography, useTheme } from '@mui/material';
import {
  CloseRounded as CloseIcon,
  LogoutRounded as LogoutIcon,
  GroupRounded as GroupIcon,
  SearchRounded as SearchIcon,
  Translate as TranslateIcon
} from '@mui/icons-material';
import { Box, useMediaQuery } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLanguage } from '../i18n/I18Context';
import { actions } from '../modules/Auth/_redux/authRedux';
import { getTeams } from '../modules/Streaming/services/live';
import CustomDropdown from './CustomDropdown';

const HeaderDesktop = ({ menuHover, menuFixed }) => {
  const { auth } = useSelector(state => ({ auth: state.auth }), shallowEqual);
  const { user, contasCargos } = auth;
  const canManageTeamsUsers = user?.canManageTeamsUsers;
  const { setLocale, locale } = useLanguage();
  const localeNameLabel = locale === 'pt-BR' ? 'Português' : 'Inglês';
  const [teamAnchorEl, setTeamAnchorEl] = useState(null);
  const [translateAnchorEl, setTranslatenchorEl] = useState(null);
  const [filter, setFilter] = useState('');
  const [teams, setTeams] = useState(contasCargos && Object.values(contasCargos));
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(992));
  const { data } = useQuery('getTeams', () => getTeams(''), {
    enabled: canManageTeamsUsers
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (canManageTeamsUsers && !!data?.items) setTeams(data.items);
  }, [canManageTeamsUsers, data]);
  const filteredTeams = teams?.filter(team => normalizeString(team.conta_nome || team.name).includes(normalizeString(filter)));
  const teamsCount = teams?.length;
  const logoutClick = () => {
    const toggle = document.getElementById('kt_quick_user_toggle');
    if (toggle) {
      toggle.click();
    }
    localStorage.removeItem('lastVisitedPage');
    history.push('/logout');
  };
  const showTeamSelector = teamsCount > 1 || canManageTeamsUsers;
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent={isMobile ? 'flex-end' : 'space-between'}
      sx={{
        bgcolor: 'common.white',
        height: 56,
        left: isMobile ? 0 : '70px',
        right: 0,
        px: 4,
        position: 'fixed',
        top: isMobile ? 70 : 0,
        zIndex: menuHover || menuFixed ? 2 : 100
      }}
    >
      {!isMobile && (
        <Link to="">
          <Box
            sx={{
              backgroundImage: `url(${process.env.REACT_APP_SPALLA_LOGO})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              height: 40,
              width: 129
            }}
          />
        </Link>
      )}
      <Stack alignItems="center" direction="row">
        <Stack sx={{ mr: 3 }}>
          <CustomDropdown
            title={localeNameLabel}
            anchorEl={translateAnchorEl}
            setAnchorEl={setTranslatenchorEl}
            icon={<TranslateIcon sx={{ color: 'primary.main' }} />}
          >
            <Stack sx={{ cursor: 'pointer' }} onClick={() => setLocale('pt-BR')}>
              <Stack sx={{ bgcolor: locale === 'pt-BR' ? 'neutral.light' : 'transparent', px: 3, py: '12px' }}>
                <Typography sx={{ color: locale === 'pt-BR' ? 'primary.main' : 'neutral.body', fontSize: 14, fontWeight: 600 }}>
                  - Português (BR)
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={{ cursor: 'pointer' }} onClick={() => setLocale('en')}>
              <Stack sx={{ bgcolor: locale === 'en' ? 'neutral.light' : 'transparent', px: 3, py: '12px' }}>
                <Typography sx={{ color: locale === 'en' ? 'primary.main' : 'neutral.body', fontSize: 14, fontWeight: 600 }}>
                  - Inglês (EUA)
                </Typography>
              </Stack>
            </Stack>
          </CustomDropdown>
        </Stack>
        {showTeamSelector && (
          <CustomDropdown
            title={<FormattedMessage id="GENERAL.HEADER.TEAM_SELECTOR" />}
            anchorEl={teamAnchorEl}
            setAnchorEl={setTeamAnchorEl}
            icon={<GroupIcon sx={{ color: 'primary.main', mr: 1 }} />}
          >
            <Stack sx={{ width: 355 }}>
              <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ px: 4, py: 3 }}>
                <Typography sx={{ color: 'neutral.dark', fontSize: 16 }}>Selecionar time</Typography>
                <IconButton onClick={() => setTeamAnchorEl(null)}>
                  <CloseIcon sx={{ color: 'muted', cursor: 'pointer' }} />
                </IconButton>
              </Stack>
              <Divider sx={{ bgcolor: 'grey.200', mb: 3 }} />
              <Stack sx={{ mb: 2, px: 3 }}>
                <OutlinedInput
                  endAdornment={<SearchIcon />}
                  placeholder="Buscar"
                  sx={{ height: 37 }}
                  onKeyDown={evt => evt.stopPropagation()}
                  onChange={evt => setFilter(evt.target.value)}
                />
              </Stack>
              <Stack sx={{ maxHeight: 230, overflowY: 'scroll' }}>
                {filteredTeams?.map(team => (
                  <Stack
                    key={team.conta_id || team.accountId}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        '.team-container': {
                          bgcolor: 'neutral.light'
                        },
                        '.team-name': {
                          color: 'primary.main'
                        }
                      }
                    }}
                    onClick={() => {
                      dispatch(
                        actions.updateUserData({
                          user: {
                            ...user,
                            ...(team.cargo_nome && { cargo: team.cargo_nome }),
                            ...(canManageTeamsUsers ? { selectedTeamId: team.accountId } : { selectedTeamId: team.conta_id }),
                            ...(canManageTeamsUsers ? { clienteSelecionado: team.accountId } : { clienteSelecionado: team.cliente })
                          },
                          permissions: canManageTeamsUsers ? [...(team?.permissoes || []), 'manageTeamUsers'] : team?.permissoes
                        })
                      );
                      setTeamAnchorEl(null);
                    }}
                  >
                    <Stack className="team-container" sx={{ px: 3, py: '12px' }}>
                      <Typography className="team-name" sx={{ color: 'neutral.body', fontSize: 14, fontWeight: 600 }}>
                        {team.conta_nome || team.name}
                      </Typography>
                      <Typography sx={{ color: 'muted', fontSize: 12, fontWeight: 600 }}>{team.cargo_nome}</Typography>
                    </Stack>
                    <Divider sx={{ bgcolor: 'grey.200' }} />
                  </Stack>
                ))}
              </Stack>
              <Button
                sx={{ m: 2 }}
                onClick={() => {
                  dispatch(actions.requestUser());
                  setTeamAnchorEl(null);
                  history.push('/');
                }}
              >
                Limpar filtros
              </Button>
            </Stack>
          </CustomDropdown>
        )}
        {!isMobile && (
          <>
            <Divider orientation="vertical" sx={{ height: 23, mx: 4 }} />
            <img
              alt="user"
              onError={e => {
                e.target.src = '/media/placeholder-user.png';
                e.target.onError = null;
              }}
              src={user.avatar}
              style={{
                borderRadius: '8px',
                height: 40,
                marginRight: '8px',
                width: 40
              }}
            />
            <Stack sx={{ mr: 4 }}>
              <Typography sx={{ color: 'neutral.body', fontWeight: 600, fontSize: 14 }}>{user.nome}</Typography>
              <Typography sx={{ color: 'neutral.body', fontWeight: 600, fontSize: 12 }}>{user.login}</Typography>
            </Stack>
          </>
        )}
        <IconButton sx={{ bgcolor: 'neutral.light', borderRadius: 1, ml: isMobile ? 4 : 0 }} onClick={logoutClick}>
          <LogoutIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default HeaderDesktop;
