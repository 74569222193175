/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Link } from '@mui/material';
import { AuthorizedSection } from '@tshio/react-router-permissions';
import { AsideMenuItem } from '@metronic/layout/components/aside/aside-menu/AsideMenuItem';
import { AsideMenuItemWithSub } from '@metronic/layout/components/aside/aside-menu/AsideMenuItemWithSub';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ProgressDrawer from '../../../../../app/components/SideMenuProgress/SideMenuProgress';
import { FormattedMessage } from 'react-intl';

export function AsideMenuList({ layoutProps }) {
  const { auth } = useSelector(state => ({ auth: state.auth }), shallowEqual);
  const { user } = auth;
  const userDirectory = user.diretorioRoot;
  return (
    <>
      <ul className={`menu-nav d-flex flex-column h-100 ${layoutProps.ulClasses}`}>
        <AsideMenuItemWithSub
          to=""
          title={<FormattedMessage id="ASIDEMENU.MENU.CONTENT" />}
          icon="/media/svg/icons/Devices/TV1.svg"
          data-testid="content-options"
        >
          <AuthorizedSection requires="showStreaming">
            {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/streaming" title="Transmissões" hasBullet={true} />}
          </AuthorizedSection>
          <AuthorizedSection requires={['broadcast']}>
            {({ isAuthorized }) =>
              isAuthorized && <AsideMenuItem to="/broadcast" title="Broadcast" hasBullet={true} data-testid="content-options-broadcast" />
            }
          </AuthorizedSection>
          <AuthorizedSection requires="showHistory">
            {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/historic" title="Histórico de transmissões" hasBullet={true} />}
          </AuthorizedSection>
          <AuthorizedSection requires="showVOD">
            {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/vod/dashboard" title="Video on Demand" hasBullet={true} />}
          </AuthorizedSection>
        </AsideMenuItemWithSub>
        <AuthorizedSection requires={['showUsers', 'gerenciarTimes', 'showRoles', 'spalla-dev', 'showFinancial', 'manageTeamUsers']}>
          {({ isAuthorized }) =>
            isAuthorized && (
              <AsideMenuItemWithSub title={<FormattedMessage id="ASIDEMENU.MENU.MANAGER" />} icon="/media/svg/icons/Home/User.svg">
                <AuthorizedSection requires={['showUsers', 'manageTeamUsers']}>
                  {({ isAuthorized }) =>
                    isAuthorized && <AsideMenuItem to="/users" title={<FormattedMessage id="ASIDEMENU.MENU.MANAGER.USERS" />} hasBullet={true} />
                  }
                </AuthorizedSection>

                <AsideMenuItem to="/records" title={<FormattedMessage id="ASIDEMENU.MENU.MANAGER.LOGS" />} hasBullet={true} beta />

                <AuthorizedSection requires={['gerenciarTimes', 'manageTeamUsers']}>
                  {({ isAuthorized }) =>
                    isAuthorized && <AsideMenuItem to="/teams" title={<FormattedMessage id="ASIDEMENU.MENU.MANAGER.TEAMS" />} hasBullet={true} />
                  }
                </AuthorizedSection>

                <AuthorizedSection requires="showRoles">
                  {({ isAuthorized }) =>
                    isAuthorized && <AsideMenuItem to="/roles" title={<FormattedMessage id="ASIDEMENU.MENU.MANAGER.ROLES" />} hasBullet={true} />
                  }
                </AuthorizedSection>

                <AuthorizedSection requires="spalla-dev">
                  {({ isAuthorized }) =>
                    isAuthorized && (
                      <AsideMenuItem to="/permissions" title={<FormattedMessage id="ASIDEMENU.MENU.MANAGER.PERMISSIONS" />} hasBullet={true} />
                    )
                  }
                </AuthorizedSection>

                <AuthorizedSection requires="showFinancial">
                  {({ isAuthorized }) =>
                    isAuthorized && <AsideMenuItem to="/billing" title={<FormattedMessage id="ASIDEMENU.MENU.MANAGER.BILLING" />} hasBullet={true} />
                  }
                </AuthorizedSection>
              </AsideMenuItemWithSub>
            )
          }
        </AuthorizedSection>

        <AuthorizedSection requires={['spalla-dev', 'showStreamingAnalytics', 'spalla_studio']}>
          {({ isAuthorized }) =>
            isAuthorized && (
              <AsideMenuItemWithSub title="Analytics" icon="/media/svg/icons/Devices/bar_chart.svg">
                <AuthorizedSection requires="showStreamingAnalytics">
                  {({ isAuthorized }) =>
                    isAuthorized && (
                      <AsideMenuItem
                        linkProperty="href"
                        Link={Link}
                        to={`${process.env.REACT_APP_ANALYTICS_URL}vod/${userDirectory}/general?isDir=true&paths=/${userDirectory}`}
                        title="Video on Demand"
                        hasBullet={true}
                      />
                    )
                  }
                </AuthorizedSection>
                <AuthorizedSection requires={['spalla-dev', 'spalla_studio']}>
                  {({ isAuthorized }) =>
                    isAuthorized && (
                      <AsideMenuItem
                        linkProperty="href"
                        Link={Link}
                        to={`${process.env.REACT_APP_ANALYTICS_URL}studio`}
                        title={process.env.REACT_APP_SPALLA_STUDIO_NAME}
                        beta
                        hasBullet={true}
                      />
                    )
                  }
                </AuthorizedSection>
              </AsideMenuItemWithSub>
            )
          }
        </AuthorizedSection>

        <AsideMenuItemWithSub title={<FormattedMessage id="ASIDEMENU.MENU.INTEGRATION" />} icon="/media/svg/icons/General/Settings-2.svg">
          <AuthorizedSection requires="api">
            {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/api-keys" title="Chaves de API" hasBullet={true} />}
          </AuthorizedSection>

          <AuthorizedSection requires={['spalla-dev', 'integrations']}>
            {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/integrations" title="Integrações" hasBullet={true} />}
          </AuthorizedSection>

          <AuthorizedSection requires="showAccountSettings">
            {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/account/settings" title="Configurações" hasBullet={true} />}
          </AuthorizedSection>
        </AsideMenuItemWithSub>

        <AuthorizedSection requires="spalla-dev">
          {({ isAuthorized }) =>
            isAuthorized && (
              <AsideMenuItemWithSub title={process.env.REACT_APP_SPALLA_NAME} icon="/media/svg/icons/Communication/Shield-thunder.svg">
                <AsideMenuItem to="/clients" title="Clientes" hasBullet={true} />
              </AsideMenuItemWithSub>
            )
          }
        </AuthorizedSection>
        <ProgressDrawer />
      </ul>
    </>
  );
}
